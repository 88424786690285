import React from 'react';

const Audio = ({ className, cls1, cls2 }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 120 120"
            className={className}
        >
            <circle cx="60" cy="60" r="40" className={cls1}  />
            <path
                className={cls2}
                d="M74.34,72.44V61.58c0-2.11,0-4.22-.14-6.31A13.61,13.61,0,0,0,59.86,42.83,13.41,13.41,0,0,0,45.71,55.37c-.28,4.87,0,9.68-.17,14.62,0,1.25.33,2.56-1.78,2.53-3.56,0-4.47-.37-4.58-2.5s0-3.92,0-5.87c0-.61.31-1.63.69-1.77,2.59-.79,1.84-2.77,1.84-4.59a17,17,0,0,1,8.78-16.34c11.5-6.37,28,0,27.8,15.9a21.18,21.18,0,0,0,0,2.76c0,.5.33,1.38.69,1.38,1.84.51,1.92,1.87,1.89,3.38s0,2.76,0,4.27c0,2.55-.62,3.14-3.2,3.16Z"
            />
            <path
                className={cls2}
                d="M65.64,67.17V62.23a3.41,3.41,0,0,1,3.15-3.61,2.81,2.81,0,0,1,.71,0,3.35,3.35,0,0,1,3.73,2.89,3.21,3.21,0,0,1,0,.62V72a3.53,3.53,0,0,1-3.44,3.62h-.32a3.63,3.63,0,0,1-3.9-3.35h0a2,2,0,0,1,0-.35C65.61,70.31,65.64,68.75,65.64,67.17Z"
            />
            <path
                className={cls2}
                d="M46.48,67.17V62.23a3.43,3.43,0,0,1,3.17-3.61,2.51,2.51,0,0,1,.67,0,3.39,3.39,0,0,1,3.76,3,2.35,2.35,0,0,1,0,.53V72a3.48,3.48,0,0,1-3.37,3.6h-.37a3.62,3.62,0,0,1-3.89-3.27v-.41C46.43,70.31,46.48,68.75,46.48,67.17Z"
            />
        </svg>
    );
};

export default Audio;