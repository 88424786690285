import React,{useEffect,useState} from 'react';
import Paper from "@material-ui/core/Paper";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import {Dialog, DialogActions,DialogTitle} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import config from "../../config/aws-exports"
import { API } from "aws-amplify";
import {Link,useNavigate} from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import Ex from './ExploreJobs.module.scss';
//import { produce } from "immer";

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const userDetails = useSelector(authData);
  let sdata = { ...userDetails };
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [masterData, setMasterData] = useState({});
    const [filterDisplay, setfilterDisplay] = useState({});
    const [filterBody, setfilterBody] = useState({});
    const [exploreJob, setExploreJob] = useState();
  const { onClose, selectedValue, open,loading, setLoading } = props;
  
  useEffect(() => {
      console.log("sdatasearchBody==",filterDisplay);
      setfilterBody({...userDetails.searchBody})
        setfilterDisplay({...userDetails.srcDisplay});
    getMasterData();
    return () => {};
  }, []);
 
  function getMasterData() {
    API.post(config.aws_cloud_logic_custom_name, "/masterData", {
        body: {
          action: 1,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: jwttoken,
        },
      }).then((res) => {
        console.log(res);
        setMasterData(res.body);
        
      })
  }
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
      <>
    <Dialog onClose={handleClose} aria-labelledby="max-width-dialog-title"
    maxWidth={'md'} open={open}>
      <DialogTitle id="simple-dialog-title">Search</DialogTitle>
    
              {!loading && userDetails.srcDisplay !== undefined &&   <div className={Ex.searchoptions}>
                    <div className={Ex.leftholder}>
                        <div className={Ex.labelandselect}>
                            <label className={Ex.label}>Company</label>
                            <select className={Ex.select} defaultValue={userDetails.srcDisplay.cid !== undefined ? parseInt(userDetails.srcDisplay.cid.ind): -1} onChange={(event) => {
                                let obj = {};
                                    filterBody.cid = masterData.company[event.target.value].cid;
                                    obj.name = masterData.company[event.target.value].cname;
                                    obj.cid =  masterData.company[event.target.value].cid;
                                    obj.ind = event.target.value;
                                    filterDisplay.cid = obj ;
                                    setfilterDisplay(filterDisplay);
                                setfilterBody(filterBody)
                            }}>
                            <option  value={-1}  selected="selected" disabled>Select Company</option>
        {masterData.company?.map((value,index) => (
          <option value={index} key={"cour"+index}>{value.cname.substring(0,40)}{value.cname.length > 40 ? "..." : null}</option>
        ))};
                            </select>
                        </div>
                        <div className={Ex.labelandselect}>
                            <label className={Ex.label}>Job title</label>
                            <select className={Ex.select} defaultValue={userDetails.srcDisplay.jid !== undefined ? parseInt(userDetails.srcDisplay.jid.ind):-1} onChange={(event) => {
                              let obj = {};
                                    filterBody.jid =  masterData.job[event.target.value].jid;
                                    obj.name = masterData.job[event.target.value].title;
                                    obj.jid =  masterData.job[event.target.value].jid;
                                    obj.ind = event.target.value;
                                    filterDisplay.jid = obj ;
                                    setfilterDisplay(filterDisplay);
                                setfilterBody(filterBody)
                            }}>
                            <option value={-1} selected="selected" disabled>Select Job</option>
                            {masterData.job?.map((value,index) => (
          <option value={index} key={"cour"+index}>{value.title.substring(0,40)}{value.title.length > 40 ? "..." : null}</option>
        ))};
                            </select>
                        </div>
                        <div className={Ex.labelandselect}>
                            <label className={Ex.label}>Location</label>
                           
                            <select className={Ex.select}
                            
                             defaultValue={userDetails.srcDisplay.lid !== undefined ? (parseInt(userDetails.srcDisplay.lid.ind)):-1} onChange={(event) => {
                                
                                    filterBody.lid = masterData.location[event.target.value].lid;
                                    let obj = {};
                                    obj.name = masterData.location[event.target.value].lname;
                                    obj.lid =  masterData.location[event.target.value].lid;
                                    obj.ind = event.target.value;
                                    filterDisplay.lid = obj ;
                                    setfilterDisplay(filterDisplay);
                                setfilterBody(filterBody)
                            }}>
                            <option value={-1} selected="selected" disabled>Select Location</option>
                            {masterData.location?.map((value,index) => (
          <option value={index} key={"cour"+index}>{value.lname.substring(0,40)}{value.lname.length > 40 ? "..." : null}</option>
        ))};
                            </select>
                        </div>
                    </div>
                    <div className={Ex.rightholder}>
                    <div className={Ex.labelandselect}>
                            <label className={Ex.label}>Employement type</label>
                            <select className={Ex.select}
                            defaultValue={userDetails.srcDisplay.etid !== undefined ? parseInt(userDetails.srcDisplay.etid.ind) :-1} 
                             onChange={(event) => {
                            
                                    filterBody.etid = masterData.employment[event.target.value].eid;
                                    let obj = {};
                                    obj.name = masterData.employment[event.target.value].emp_type;
                                    obj.etid =  masterData.employment[event.target.value].eid;
                                    obj.ind = event.target.value;
                                    filterDisplay.etid = obj ;
                                    setfilterDisplay(filterDisplay);
                                setfilterBody(filterBody)
                            }}>
                            <option value={-1} selected="selected" disabled>Select Employement</option>
                            {masterData.employment?.map((value,index) => (
          <option value={index} key={"cour"+index}>{value.emp_type.substring(0,40)}{value.emp_type.length > 40 ? "..." : null}</option>
        ))};
                            </select>
                        </div>
                        <div className={Ex.labelandselect}>
                            <label className={Ex.label}>Required Experience</label>

                            <select className={Ex.select} 
                            defaultValue={userDetails.srcDisplay.req_id !== undefined ? parseInt(userDetails.srcDisplay.req_id.ind):-1} 
                             onChange={(event) => {
                                
                                    filterBody.req_exp = masterData.reqexp[event.target.value].req_id;
                                    let obj = {};
                                    obj.name = masterData.reqexp[event.target.value].req_name;
                                    obj.req_id =  masterData.reqexp[event.target.value].req_id;
                                    obj.ind = event.target.value;
                                    filterDisplay.req_id = obj ;
                                    setfilterDisplay(filterDisplay);
                                setfilterBody(filterBody)
                            }}>
                               < option value={-1} selected="selected" disabled>Select Experience</option>
                            {masterData.reqexp?.map((value,index) => (
          <option value={index} key={"cour"+index}>{value.req_name.substring(0,40)}{value.req_name.length > 40 ? "..." : null}</option>
        ))};
                            </select>
                        </div>
                        <div className={Ex.labelandselect}>
                            <label className={Ex.label}>Job Status</label>
                            <select className={Ex.select} 
                             defaultValue={userDetails.srcDisplay.jstatus !== undefined ? userDetails.srcDisplay.jstatus.ind:-1}
                              onChange={(event) => {
                                console.log("llll==",event.target.value)
                                 let obj = {};
                                if(event.target.value == 'true'){
                                    filterBody.jstatus = true;
                                    filterDisplay.jstatus = 'Open';
                                     obj.name = 'Open';
                                } else {
                                    filterBody.jstatus = false;
                                    filterDisplay.jstatus = 'close';
                                     obj.name = 'close';
                                }
                                    obj.jstatus = filterBody.jstatus;
                                    obj.ind = event.target.value;
                                    filterDisplay.jstatus = obj ;
                                setfilterDisplay(filterDisplay);
                                setfilterBody(filterBody)
                            }}>
                                <option value=''>Select</option>
                                <option value='true'>open</option>
                                <option value='false'>close</option>
                            </select>
                        </div>
                    </div>
                </div>}
            
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            search
          </Button>
        </DialogActions>
    </Dialog>
    
    </>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add filter
      </Button>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}
