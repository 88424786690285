import React,{useEffect,useState} from 'react';
import Paper from "@material-ui/core/Paper";
import {Link,useNavigate} from 'react-router-dom';

// Local imports
import logo from '../../assets/images/logo.jpg';
import {ReactComponent as BookMarkBorder} from '../../assets/svg/bookmark_border_black_24dp.svg';
import {ReactComponent as BookMark} from '../../assets/svg/bookmark_black_24dp.svg';
import config from "../../config/aws-exports"
import { API } from "aws-amplify";
import { useSelector,useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
// Style imports
import Ex from './ExploreJobs.module.scss';
const SaveJob = (props) => {
    let { stype,jid,setSavedJob, savedJob, index,setLoading, ptype} =props;
    const userDetails = useSelector(authData);
    const dispatch = useDispatch();
    let navigate = useNavigate();
   
    //const [exploreJob, setExploreJob] = useState();
    function submitJob(rmsv) {
        console.log({
            jobid: jid,
            eid: userDetails.eid,
            apstatus: 0,
            statustype: 2,
            rmsv
        },"kkk==",jid)
        setLoading(true);
        API.post(config.aws_cloud_logic_custom_name, "/appliedSavedJob", {
            body: {
                jobid: jid,
                eid: userDetails.eid,
                apstatus: 0,
                statustype: 2,
                rmsv
            },
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // Authorization: jwttoken,
            },
          }).then((res) => {
            console.log(rmsv);
            if(rmsv !== undefined){
                savedJob.splice(index,1);
            } else {
                savedJob.push(jid);
            }
            setSavedJob(savedJob);
            setLoading(false);
            console.log("ptype==",ptype)
           
            //navigate(userDetails.prepage);
           // setMasterData(res.body);
          })
    }
    return(
       
        <div className={Ex.iconholder} >
            {stype === true && < BookMark className={Ex.icon} style={{cursor:'pointer'}} 
            onClick={()=>submitJob('rmsave')}/>}
            {(stype === undefined || stype === false) && <BookMarkBorder className={Ex.icon}
            onClick={()=>submitJob()} style={{cursor:'pointer'}} />}
        </div>
       
    )
};
    export default SaveJob;