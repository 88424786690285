import React from 'react';

const Delete = ({ className, fill }) => {
  return (
<svg version="1.0" className={className} xmlns="http://www.w3.org/2000/svg"
 width="225.000000pt" height="225.000000pt" viewBox="0 0 225.000000 225.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
 stroke="none">
<path d="M875 2219 c-100 -48 -167 -145 -181 -261 l-6 -57 -190 -3 c-221 -4
-222 -4 -234 -109 l-7 -59 -38 0 -39 0 0 -84 0 -83 31 -7 c17 -3 56 -6 85 -6
l54 0 0 -671 0 -670 25 -54 c28 -58 79 -109 131 -131 27 -11 146 -14 624 -14
666 0 628 -4 703 79 70 78 67 40 67 787 l0 674 59 0 c32 0 73 3 90 6 l31 7 0
83 0 84 -45 0 -45 0 0 54 c0 44 -5 61 -24 83 l-24 28 -191 3 -191 3 0 44 c0
105 -74 220 -173 270 -50 24 -55 25 -261 25 -189 -1 -215 -3 -251 -21z m430
-163 c43 -18 75 -69 75 -118 l0 -38 -255 0 -255 0 0 28 c1 47 34 107 71 125
46 22 312 24 364 3z m425 -1165 l0 -660 -24 -28 -24 -28 -526 -3 c-554 -3
-595 0 -618 41 -10 17 -14 172 -16 680 l-3 657 605 0 606 0 0 -659z"/>
<path d="M700 865 l0 -515 85 0 85 0 0 515 0 515 -85 0 -85 0 0 -515z"/>
<path d="M1040 865 l0 -515 85 0 85 0 0 515 0 515 -85 0 -85 0 0 -515z"/>
<path d="M1390 865 l0 -515 85 0 85 0 0 515 0 515 -85 0 -85 0 0 -515z"/>
</g>
</svg>
 )
}
export default Delete;