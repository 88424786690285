// Dependencies imports
import React,{useEffect,useState} from 'react';
import { Tab } from "semantic-ui-react";
import Paper from "@material-ui/core/Paper";
import {Link,useNavigate} from 'react-router-dom';

// Local imports
import {ReactComponent as BookMark} from '../../assets/svg/bookmark_black_24dp.svg';
import {ReactComponent as BookMarkBorder} from '../../assets/svg/bookmark_border_black_24dp.svg';
import logo from '../../assets/images/logo.jpg';

import config from "../../config/aws-exports"
import { API } from "aws-amplify";
import { useSelector,useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import SaveJob from "./SavedJob"
import UserHeader from '../Header/UserHeader/UserHeader';
// Style imports
import My from './MyJobs.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';

const MyJobs = () => {
    const userDetails = useSelector(authData);
    const [viewAppData, setViewAppData] = useState();
    const [viewSaveData, setViewSaveData] = useState();
    const [savedJob, setSavedJob] = useState([]);
    const [loading, setLoading] = useState(true);
    const [appJob, setAppJob] = useState([]);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    useEffect(() => {
      
        getViewData();
        return () => {};
      }, []);
      function getViewData() {
         
        API.post(config.aws_cloud_logic_custom_name, "/viewAppliedSavedJob", {
            body: {
                eid:userDetails.eid
            },
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // Authorization: jwttoken,
            },
          }).then((res) => {
           
            if(res.body !== undefined && res.body.pfstatus !== undefined){
            let sdata = { ...userDetails };
            sdata.pfstatus = res.body.pfstatus;
             dispatch(awsSignIn(sdata));
            }
            if(res.body !== undefined ){
              let appd = res.body.saview.filter(function checkAdult(item) {
                return item.status_type === 1 || item.status_type === 3 ;
              });
           
            setViewAppData(appd);
            let svd = res.body.saview.filter(function checkAdult(item) {
                return item.status_type === 2 || item.status_type === 3 ;
              });
             
            setViewSaveData(svd);
              }
            if(res.body !== undefined && res.body.savejobs !== undefined){
                 setSavedJob(res.body.savejobs);
            }
            if(res.body !== undefined && res.body.appjobs !== undefined){
               setAppJob(res.body.appjobs);
            }
            setLoading(false);
          })
      }
      function submitJob(jid,index,rmsv) {
         setLoading(true);
        API.post(config.aws_cloud_logic_custom_name, "/appliedSavedJob", {
            body: {
                jobid: jid,
                eid: userDetails.eid,
                apstatus: 0,
                statustype: 2,
                rmsv
            },
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // Authorization: jwttoken,
            },
          }).then((res) => {
           
           getViewData();
            //navigate(userDetails.prepage);
           // setMasterData(res.body);
          })
      }
    const panes = [
        {
          menuItem: "Applied Jobs",
          render: () => (
            <Tab.Pane>
              <div className={My.recentbox}>
               {loading && <>
                   {[1,2,3,4].map((val,index)=>(
                     <div className={My.jobcard}>
                    <Paper style={{padding:"20px"}}>
                    <Skeleton animation="wave" width="60%" height={30} />
                    <Skeleton animation="wave" width="60%" height={30} />
                    <Skeleton animation="wave" width="60%" height={30} />
                     <Skeleton animation="wave"  height={20} />
                     <Skeleton animation="wave"  height={20} />
                     <Skeleton animation="wave"  height={20} />
                    </Paper>
                    </div>
                   ))}
              </>}
              {!loading && viewAppData?.map((val,index) => (
                    <div className={My.jobcard}>
                    <Paper style={{padding:"20px"}}>
                        <div className={My.topholder}>
                            <div className={My.imageholder}>
                                <img className={My.logo} src={logo} alt="" />
                            </div>
                            <div className={My.titleholder} >
                                <h4 className={My.jobtitle}>{val.title}</h4>
                                <h4 className={My.jobtitle}>{val.cname}</h4>
                            </div>
                        
                             <div className={My.iconholder} >
            {(savedJob?.length > 0 && savedJob.includes(val.jid) ) && < BookMark className={My.icon} style={{cursor:'pointer'}} 
            onClick={()=>submitJob(val.jid,index,'rmsave')}/>}
            {(!(savedJob?.length > 0 && savedJob.includes(val.jid))) && <BookMarkBorder className={My.icon}
            onClick={()=>submitJob(val.jid,index)} style={{cursor:'pointer'}} />}
        </div>
                        </div>
                        <div className={My.typeholder}>
                        <h5 className={My.finaltext}>{val.etype}</h5>
                        <ul className={My.list}>
                            <li className={My.finaltext}>{val.lname}</li>
                        </ul>
                        </div>
                        <div className={My.dateholder}>
                        <h5 className={My.shorttext}>Expires on: {val.op_end_date}</h5>
                        <h5 className={My.shorttext}>Posted by: {val.postby}</h5>
                        </div>
                        <h6 className={My.applicants}>{val.app_count} Applicants</h6>
                        <div className={My.statusholder}>
                            <h5 className={My.applicants}>Status: <span className={My.status}>{val.status === true ? 'Open':'close'}</span></h5>
               <button className={My.viewjob} 
                onClick={()=>{
                   
                    let sdata = { ...userDetails };
                    sdata.vjob = val;
                    sdata.appJob = appJob;
                    sdata.prepage = '/myjobs';
                     dispatch(awsSignIn(sdata));
                     navigate("/viewjobs");
                }}>View Job</button>
                        </div>
                        </Paper>
                    </div>
                     ))}
                 {!loading && (viewAppData === undefined || viewAppData?.length === 0) &&
                <h3>
                  There are no Applied Jobs here!
                </h3>
                }
            </div>
            
            </Tab.Pane>
          ),
        },
        {
          menuItem: "Saved Jobs",
          render: () => (
            <Tab.Pane>
              <div className={My.recentbox}>
              {loading && <>
                 {[1,2,3,4].map((val,index)=>(
                     <div className={My.jobcard}>
                     <Paper style={{padding:"20px"}}>
                    <Skeleton animation="wave" width="60%" height={30} />
                    <Skeleton animation="wave" width="60%" height={30} />
                    <Skeleton animation="wave" width="60%" height={30} />
                     <Skeleton animation="wave"  height={20} />
                     <Skeleton animation="wave"  height={20} />
                     <Skeleton animation="wave"  height={20} />
                    </Paper>
                    </div>
                   ))}
              </>}
              {!loading && viewSaveData?.map((val,index) => (
                    <div className={My.jobcard}>
                    <Paper style={{padding:"20px"}}>
                        <div className={My.topholder}>
                            <div className={My.imageholder}>
                                <img className={My.logo} src={logo} alt="" />
                            </div>
                            <div className={My.titleholder} >
                            <h4 className={My.jobtitle}>{val.title}</h4>
                                <h4 className={My.jobtitle}>{val.cname}</h4>
                            </div>
                          <div className={My.iconholder} >
            {(savedJob?.length > 0 && savedJob.includes(val.jid) ) && < BookMark className={My.icon} style={{cursor:'pointer'}} 
            onClick={()=>submitJob(val.jid,index,'rmsave')}/>}
        </div>
                        </div>
                        <div className={My.typeholder}>
                        <h5 className={My.finaltext}>{val.etype}</h5>
                        <ul className={My.list}>
                            <li className={My.finaltext}>{val.lname}</li>
                        </ul>
                        </div>
                        <div className={My.dateholder}>
                        <h5 className={My.shorttext}>Expires on: {val.op_end_date}</h5>
                        <h5 className={My.shorttext}>Posted by: {val.postby}</h5>
                        </div>
                        <h6 className={My.applicants}>{val.app_count} Applicants</h6>
                        <div className={My.statusholder}>
                            <h5 className={My.applicants}>Status:  {val.status && <span className={My.status} > Open</span>}
                            {!val.status && <span  > Close</span>}</h5>
                            <button className={My.viewjob} 
                onClick={()=>{
                  
                    let sdata = { ...userDetails };
                    sdata.vjob = val;
                    sdata.prepage = '/myjobs';
                     dispatch(awsSignIn(sdata));
                     navigate("/viewjobs");
                }}>View Job</button>
                        </div>
                        </Paper>
                    </div>
                     ))}
                {!loading && (viewSaveData === undefined || viewSaveData?.length === 0) &&
                <h3>
                   There are no Saved Jobs here!
                </h3>
                }
            </div>
            </Tab.Pane>
          ),
        },
    ]


    return(
        <div className={My.maincontainer}>
          <UserHeader />
            <div className={My.tabholder}>
                <Tab panes={panes} />
            </div>
        </div>
    );
};

export default MyJobs;