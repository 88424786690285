import React from 'react';

const DashboardIcon = ({ className, fill }) => {
  return (
    <svg
      className={className}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      width='23'
      height='25'
      viewBox='0 0 32 32'
    >
      <title>Dashboard-1</title>
      <path d='M0.996 0.256c-1.010 0.498-0.996 0.356-0.996 8.604 0 8.121 0 8.092 0.882 8.633 0.455 0.27 0.697 0.284 6.201 0.284 6.329 0 6.315 0 6.855-0.896 0.27-0.441 0.284-0.768 0.284-7.993 0-7.31-0.014-7.552-0.284-8.007-0.526-0.868-0.668-0.882-6.855-0.882-5.035 0-5.632 0.028-6.087 0.256z'></path>
      <path d='M18.816 0.213c-0.256 0.114-0.597 0.412-0.754 0.668-0.27 0.441-0.284 0.711-0.284 4.452s0.014 4.011 0.284 4.452c0.526 0.868 0.668 0.882 6.855 0.882 5.035 0 5.632-0.028 6.087-0.256 0.939-0.455 0.996-0.754 0.996-5.077s-0.057-4.622-0.996-5.077c-0.455-0.228-1.038-0.256-6.116-0.256-4.508 0.014-5.703 0.057-6.073 0.213z'></path>
      <path d='M18.674 14.507c-0.91 0.555-0.896 0.356-0.896 8.562 0 6.798 0.028 7.481 0.256 7.936 0.484 0.981 0.555 0.996 6.855 0.996s6.372-0.014 6.855-0.996c0.228-0.455 0.256-1.138 0.256-7.865 0-8.121 0-8.092-0.882-8.633-0.455-0.27-0.697-0.284-6.229-0.284-5.461 0-5.788 0.014-6.215 0.284z'></path>
      <path d='M0.996 21.589c-0.939 0.455-0.996 0.754-0.996 5.077s0.057 4.622 0.996 5.077c0.455 0.228 1.052 0.256 6.087 0.256 6.187 0 6.329-0.014 6.855-0.882 0.27-0.441 0.284-0.711 0.284-4.452s-0.014-4.011-0.284-4.452c-0.526-0.868-0.668-0.882-6.855-0.882-5.035 0-5.632 0.028-6.087 0.256z'></path>
    </svg>
  );
};

export default DashboardIcon;
