import React from "react";
import AcSheduleStyle from "./AcademicSchedule.module.scss";

export default function AcademicSchedule() {
  return (
    <div>
      <h1>AcademicSchedule</h1>
    </div>
  );
}
