// Dependencies imports
import React,{useEffect,useState} from 'react';
import Paper from "@material-ui/core/Paper";
import {Link,useNavigate} from 'react-router-dom';

// Local imports
import logo from '../../assets/images/logo.jpg';
import {ReactComponent as BookMarkBorder} from '../../assets/svg/bookmark_border_black_24dp.svg';
import {ReactComponent as BookMark} from '../../assets/svg/bookmark_black_24dp.svg';
import config from "../../config/aws-exports"
import { API } from "aws-amplify";
import { useSelector,useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import SaveJob from "./SavedJob"
// Style imports
import Ex from './ExploreJobs.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';
import UserHeader from '../Header/UserHeader/UserHeader';

const ExploreJobs = () => {
    const userDetails = useSelector(authData);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [masterData, setMasterData] = useState({});
    const [loading, setLoading] = useState(true);
    const [filterBody, setfilterBody] = useState({});
    const [filterDisplay, setfilterDisplay] = useState({});
    const [exploreJob, setExploreJob] = useState();
    const [savedJob, setSavedJob] = useState([]);
    const [appJob, setAppJob] = useState([]);
  useEffect(() => {
    getMasterData();
    getExploreJob();
    return () => {};
  }, []);
  function getMasterData() {
    API.post(config.aws_cloud_logic_custom_name, "/masterData", {
        body: {
          action: 1,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: jwttoken,
        },
      }).then((res) => {
        console.log(res);
        setMasterData(res.body);
        
      })
  }
  function getExploreJob() {
    API.post(config.aws_cloud_logic_custom_name, "/exploreJob", {
        body: {
            eid:userDetails.eid
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: jwttoken,
        },
      }).then((res) => {
        setLoading(false);
         if(res.body !== undefined && res.body.jobs !== undefined){
                  setExploreJob(res.body.jobs);
            } 
            if(res.body !== undefined && res.body.savejobs !== undefined){
                 setSavedJob(res.body.savejobs);
            }
            if(res.body !== undefined && res.body.appjobs !== undefined){
            setAppJob(res.body.appjobs);
            }
            if(res.body !== undefined && res.body.pfstatus !== undefined){
                let sdata = { ...userDetails };
                sdata.pfstatus = res.body.pfstatus;
                 dispatch(awsSignIn(sdata));
                }
        // setExploreJob(res.body.jobs);
        // console.log("savedjobs===",res.body.jobs)
        // setSavedJob(res.body.savejobs);
        // setAppJob(res.body.appjobs);
      })
  }

    return(
        <div className={Ex.main}>
            <UserHeader />
            <div className={Ex.tobbar}>
                <h3 className={Ex.heading}>Search</h3>
                <Paper>
                <div className={Ex.searchoptions}>
                    <div className={Ex.leftholder}>
                        <div className={Ex.labelandselect}>
                            <label className={Ex.label}>Company</label>
                           
                            <select className={Ex.select} onChange={(event) => {
                                let obj = {};
                                    filterBody.cid = masterData.company[event.target.value].cid;
                                    obj.name = masterData.company[event.target.value].cname;
                                    obj.cid =  masterData.company[event.target.value].cid;
                                    obj.ind = event.target.value;
                                    filterDisplay.cid = obj ;
                                    setfilterDisplay(filterDisplay);
                                setfilterBody(filterBody)
                            }}>
                            <option selected="selected" disabled>Select Company</option>
        {masterData.company?.map((value,index) => (
          <option value={index} key={"cour"+index}>{value.cname.substring(0,40)}{value.cname.length > 40 ? "..." : null}</option>
        ))};
                            </select>
                        </div>
                        <div className={Ex.labelandselect}>
                            <label className={Ex.label}>Job title</label>
                            <select className={Ex.select} onChange={(event) => {
                              let obj = {};
                                    filterBody.jid =  masterData.job[event.target.value].jid;
                                    obj.name = masterData.job[event.target.value].title;
                                    obj.jid =  masterData.job[event.target.value].jid;
                                    obj.ind = event.target.value;
                                    filterDisplay.jid = obj ;
                                    setfilterDisplay(filterDisplay);
                                setfilterBody(filterBody)
                            }}>
                            <option selected="selected" disabled>Select Job</option>
                            {masterData.job?.map((value,index) => (
          <option value={index} key={"cour"+index}>{value.title.substring(0,40)}{value.title.length > 40 ? "..." : null}</option>
        ))};
                            </select>
                        </div>
                        <div className={Ex.labelandselect}>
                            <label className={Ex.label}>Location</label>
                            <select className={Ex.select} onChange={(event) => {
                                
                                    filterBody.lid = masterData.location[event.target.value].lid;
                                    let obj = {};
                                    obj.name = masterData.location[event.target.value].lname;
                                    obj.lid =  masterData.location[event.target.value].lid;
                                    obj.ind = event.target.value;
                                    filterDisplay.lid = obj ;
                                    console.log("obbbjjj==",obj)
                                    setfilterDisplay(filterDisplay);
                                setfilterBody(filterBody)
                            }}>
                            <option selected="selected" disabled>Select Location</option>
                            {masterData.location?.map((value,index) => (
          <option value={index} key={"cour"+index}>{value.lname.substring(0,40)}{value.lname.length > 40 ? "..." : null}</option>
        ))};
                            </select>
                        </div>
                    </div>
                    <div className={Ex.rightholder}>
                    <div className={Ex.labelandselect}>
                            <label className={Ex.label}>Employement type</label>
                            <select className={Ex.select}  onChange={(event) => {
                            
                                    filterBody.etid = masterData.employment[event.target.value].eid;
                                    let obj = {};
                                    obj.name = masterData.employment[event.target.value].emp_type;
                                    obj.etid =  masterData.employment[event.target.value].eid;
                                    obj.ind = event.target.value;
                                    filterDisplay.etid = obj ;
                                    setfilterDisplay(filterDisplay);
                                setfilterBody(filterBody)
                            }}>
                            <option selected="selected" disabled>Select Employement</option>
                            {masterData.employment?.map((value,index) => (
          <option value={index} key={"cour"+index}>{value.emp_type.substring(0,40)}{value.emp_type.length > 40 ? "..." : null}</option>
        ))};
                            </select>
                        </div>
                        <div className={Ex.labelandselect}>
                            <label className={Ex.label}>Required Experience</label>

                            <select className={Ex.select} onChange={(event) => {
                                
                                    filterBody.req_exp = masterData.reqexp[event.target.value].req_id;
                                    let obj = {};
                                    obj.name = masterData.reqexp[event.target.value].req_name;
                                    obj.req_id =  masterData.reqexp[event.target.value].req_id;
                                    obj.ind = event.target.value;
                                    filterDisplay.req_id = obj ;
                                    setfilterDisplay(filterDisplay);
                                setfilterBody(filterBody)
                            }}>
                               < option selected="selected" disabled>Select Experience</option>
                            {masterData.reqexp?.map((value,index) => (
          <option value={index} key={"cour"+index}>{value.req_name.substring(0,40)}{value.req_name.length > 40 ? "..." : null}</option>
        ))};
                            </select>
                        </div>
                        <div className={Ex.labelandselect}>
                            <label className={Ex.label}>Job Status</label>
                            <select className={Ex.select} onChange={(event) => {
                                console.log("llll==",event.target.value)
                                 let obj = {};
                                if(event.target.value == 'true'){
                                    filterBody.jstatus = true;
                                    filterDisplay.jstatus = 'Open';
                                     obj.name = 'Open';
                                } else {
                                    filterBody.jstatus = false;
                                    filterDisplay.jstatus = 'close';
                                     obj.name = 'close';
                                }
                                    obj.jstatus = filterBody.jstatus;
                                    obj.ind = filterBody.jstatus;
                                    filterDisplay.jstatus = obj ;
                                setfilterDisplay(filterDisplay);
                                setfilterBody(filterBody)
                            }}>
                                <option value=''>Select</option>
                                <option value='true'>open</option>
                                <option value='false'>close</option>
                            </select>
                        </div>
                    </div>
                </div>
                </Paper>
                <div className={Ex.buttonholder}>
                
                <button className={Ex.searchbutton}
                onClick={()=>{
                    if(Object.keys(filterBody).length >0){
                     let sdata = { ...userDetails };
                    filterBody.action =0;
                    filterBody.eid=userDetails.eid;
                    sdata.searchBody = filterBody;
                    sdata.srcDisplay = filterDisplay;
                     sdata.prepage = '/explorejobs';
                     dispatch(awsSignIn(sdata));
                     navigate("/searchresults");
                    } else {
                        alert('At list one filter select')
                    }
                    

                }}>Search
               
                </button>
                </div>
            </div>
            <hr />
            <div className={Ex.bottombar}>
                <h3 className={Ex.heading}>
                    Most recent jobs
                </h3>
                <Paper>
                    <div className={Ex.recentbox}>
                     {loading && <>
                 {[1,2,3,4,5,6].map((val,index)=>(
                     <div className={Ex.jobcard}>
                     <Paper style={{padding:"20px"}}>
                    <Skeleton animation="wave" width="60%" height={30} />
                    <Skeleton animation="wave" width="60%" height={30} />
                    <Skeleton animation="wave" width="60%" height={30} />
                     <Skeleton animation="wave"  height={20} />
                     <Skeleton animation="wave"  height={20} />
                     <Skeleton animation="wave"  height={20} />
                    </Paper>
                    </div>
                   ))}
              </>}
                    {!loading && exploreJob?.map((val,index) => (
                            <div className={Ex.jobcard}>
                            <Paper style={{padding:"20px"}}>
                                <div className={Ex.topholder}>
                                    <div className={Ex.imageholder}>
                                        <img className={Ex.logo} src={logo} alt="" />
                                    </div>
                                    <div className={Ex.titleholder} >
                                        <h4 className={Ex.jobtitle}>{val.title}</h4>
                                        <h4 className={Ex.jobtitle}>{val.cname}</h4>
                                    </div>
                                   
                                    <SaveJob stype={(savedJob?.length > 0 && savedJob.includes(val.jid) )? true : false }  jid={val.jid} 
                                    setSavedJob={setSavedJob} savedJob={savedJob} index={index} setLoading={setLoading} />
                                </div>
                                <div className={Ex.typeholder}>
                                <h5 className={Ex.finaltext}>{val.etype}</h5>
                                <ul className={Ex.list}>
                                    <li className={Ex.finaltext}>{val.lname}</li>
                                </ul>
                                </div>
                                <div className={Ex.dateholder}>
                                <h5 className={Ex.shorttext}>Expires on: {val.op_end_date}</h5>
                                <h5 className={Ex.shorttext}>Posted by: {val.postby}</h5>
                                </div>
                                <h6 className={Ex.applicants}>{val.app_count} Applicants</h6>
                                <div className={Ex.statusholder}>
                                    <h5 className={Ex.applicants}>Status: {val.status && <span className={Ex.status} > Open</span>}
                            {!val.status && <span  > Close</span>}</h5>
                                    <button className={Ex.viewjob} onClick={()=>{
                                        console.log(val)
                                        let sdata = { ...userDetails };
                                        sdata.vjob = val;
                                        sdata.appJob = appJob;
                                        sdata.prepage = '/explorejobs';
                                         dispatch(awsSignIn(sdata));
                                         navigate("/viewjobs");
                                    }}>View Job</button>
                                </div>
                                </Paper>
                            </div>
                            ))};
                    </div>
                </Paper>
            </div>
        </div>
    );
};

export default ExploreJobs;