import React from 'react';

const Review = ({ className, cls1, cls2 }) => {
  return (
    <svg
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 120 120'
      className={className}
    >
      <defs></defs>
      <path
        className={cls1}
        d='M75.62,81a19,19,0,1,1,4.26-4.18c.19.2.38.42.59.63Q86.2,83.2,91.94,88.94a3.07,3.07,0,0,1,1,3.44,2.85,2.85,0,0,1-4.47,1.31c-1.55-1.33-3-2.83-4.41-4.27Q80,85.39,76,81.35C75.88,81.23,75.74,81.12,75.62,81Z'
      />
      <path
        className={cls2}
        d='M82.64,50.53c-1-1-1.83-1.88-2.67-2.81a1.3,1.3,0,0,1-.24-.76c0-.84,0-1.68,0-2.52a3.27,3.27,0,0,0-3.25-3.33c-1.82,0-3.65,0-5.47,0a3.38,3.38,0,0,1-3.41-3.35c.07-2.13,0-4.26,0-6.39a2.88,2.88,0,0,0-3-3.09c-10.21.06-20.41,0-30.62,0-2.61,0-3.81,1.18-3.81,3.77V88c0,2.45,1.2,3.66,3.64,3.66H75.85a4.26,4.26,0,0,0,2.73-.87.65.65,0,0,1,.62,0c.68.57,1.32,1.18,2,1.8A6.47,6.47,0,0,1,76,95c-13.24,0-26.47,0-39.71,0a28.49,28.49,0,0,1-4.34-.17,6.24,6.24,0,0,1-5-6.28c0-6.5,0-13,0-19.52,0-12.36,0-24.73,0-37.1a6.51,6.51,0,0,1,4.58-6.53,7.9,7.9,0,0,1,2.05-.27q16.74,0,33.48,0a5.63,5.63,0,0,1,4,1.68c1.73,1.71,3.39,3.49,5.09,5.24s3.15,3.19,4.69,4.82a6.65,6.65,0,0,1,1.89,4.76C82.63,44.5,82.64,47.44,82.64,50.53Z'
      />
    </svg>
  );
};

export default Review;
