// Dependencies imports
import React,{useEffect,useState} from 'react';
import Paper from "@material-ui/core/Paper";
import {Link,useNavigate} from 'react-router-dom';

// Local imports
import {ReactComponent as Close} from '../../assets/svg/close_black_24dp.svg';
import {ReactComponent as BookMark} from '../../assets/svg/bookmark_black_24dp.svg';
import logo from '../../assets/images/logo.jpg';

import config from "../../config/aws-exports"
import { API } from "aws-amplify";
import { useSelector,useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import SaveJob from "./SavedJob"
import SimpleDialogDemo from './AddFilters';
// Style imports
import Sea from './SearchResult.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';
import UserHeader from '../Header/UserHeader/UserHeader';
//import { produce } from "immer";

const SearchResults = () => {
    const userDetails = useSelector(authData);
    const [searchJob, setSearchJob] = useState();
    const [searchDisplay, setSearchDisplay] = useState();
    const [savedJob, setSavedJob] = useState([]);
    const [loading, setLoading] = useState(true);
    const [appJob, setAppJob] = useState([]);
   
    const dispatch = useDispatch();
   
    let navigate = useNavigate();
    useEffect(() => {
        setSearchDisplay(userDetails.srcDisplay);
      
        getSearchData();
        return () => { };
      }, []);
      function getSearchData(searchBody) {
          console.log(userDetails.searchBody);
          setLoading(true);
        API.post(config.aws_cloud_logic_custom_name, "/exploreJob", {
            body: searchBody === undefined ? userDetails.searchBody: searchBody,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // Authorization: jwttoken,
            },
          }).then((res) => {
            console.log(userDetails.searchBody,"searchdata===",res.body);
            //setMasterData(res.body);
            if(res.body !== undefined && res.body.jobs !== undefined){
                  setSearchJob(res.body.jobs);
            } 
            if(res.body !== undefined && res.body.savejobs !== undefined){
                 setSavedJob(res.body.savejobs);
            }
            if(res.body !== undefined && res.body.appjobs !== undefined){
            setAppJob(res.body.appjobs);
            }
            if(res.body !== undefined && res.body.pfstatus !== undefined){
                let sdata = { ...userDetails };
                sdata.pfstatus = res.body.pfstatus;
                 dispatch(awsSignIn(sdata));
                }
           
            
            setLoading(false);
          })
      }
      function compare( a, b ) {
        if ( a.title < b.title ){
          return -1;
        }
        if ( a.title > b.title ){
          return 1;
        }
        return 0;
      }
      function clearfilter(val) {
          console.log("check==",searchDisplay,val)
          var copysrhdis = Object.assign({}, searchDisplay);
          delete copysrhdis[val];
         
          setSearchDisplay(copysrhdis);
            let sdata = { ...userDetails };
           
          if(Object.keys(copysrhdis).length >0){
        
          sdata.searchBody = {};
            sdata.searchBody.action =0;
            sdata.searchBody.eid= userDetails.eid;
            for (let index = 0; index < Object.keys(copysrhdis).length; index++) {
                const element = Object.keys(copysrhdis)[index];
                sdata.searchBody[element] = copysrhdis[element][element];
            }
            sdata.srcDisplay = copysrhdis;
            console.log("sdatasearchBody===",sdata.searchBody)
            sdata.srcDisplay = copysrhdis;
            // setfilterBody({...sdata.searchBody})
            // setfilterDisplay({...copysrhdis});
                dispatch(awsSignIn(sdata));
                getSearchData(sdata.searchBody);
         } else {
             navigate(sdata.prepage);
         }
      }
     
    return(
        <div className={Sea.main}>
            <UserHeader />
            <div className={Sea.topbar}>
            <h3 className={Sea.heading}>Search results</h3>
            {/* <button className={Sea.add}>Add filter</button> */}
            <SimpleDialogDemo setLoading={setLoading} loading={loading} 
            />
            <div className={Sea.sortholder}>
                <h4 className={Sea.filter}>Sort by:</h4>
                <select className={Sea.select}  onChange={(event) => {
                    setLoading(true);
                                 
                                  setSearchJob(searchobj => [...searchobj.sort( compare ) ]);
                                  setLoading(false);
                            }}>
                     <option selected="selected" disabled>Select</option>
                    <option value='title'>Name</option>
                </select>
            </div>
            </div>
            <Paper>
            <div className={Sea.filterbar}>
                <h4 className={Sea.filter}>Filtered by:</h4>
                {searchDisplay !== undefined && Object.keys(searchDisplay).length > 0 && Object.keys(searchDisplay).map((val,index) => (
                <div className={Sea.tag}>
                    <h4 className={Sea.tagname}>{searchDisplay[val].name}</h4>
                    <Close className={Sea.close} onClick={()=>clearfilter(val)}/>
                </div>
                    ))}
            </div>
            <div className={Sea.recentbox}>
             {loading && <>
                 {[1,2,3,4,5,6].map((val,index)=>(
                     <div className={Sea.jobcard}>
                     <Paper style={{padding:"20px"}}>
                    <Skeleton animation="wave" width="60%" height={30} />
                    <Skeleton animation="wave" width="60%" height={30} />
                    <Skeleton animation="wave" width="60%" height={30} />
                     <Skeleton animation="wave"  height={20} />
                     <Skeleton animation="wave"  height={20} />
                     <Skeleton animation="wave"  height={20} />
                    </Paper>
                    </div>
                   ))}
              </>}
            {!loading && searchJob?.map((val,index) => (
                    <div className={Sea.jobcard}>
                    <Paper style={{padding:"20px"}}>
                        <div className={Sea.topholder}>
                            <div className={Sea.imageholder}>
                                <img className={Sea.logo} src={logo} alt="" />
                            </div>
                            <div className={Sea.titleholder} >
                            <h4 className={Sea.jobtitle}>{val.title}</h4>
                            <h4 className={Sea.jobtitle}>{val.cname}</h4>
                            </div>
                            <div className={Sea.iconholder}>
                                <SaveJob stype={(savedJob?.length > 0 && savedJob.includes(val.jid) )? true : false } 
                                jid={val.jid} setSavedJob={setSavedJob} savedJob={savedJob} index={index} setLoading={setLoading} />
                            </div>
                        </div>
                        <div className={Sea.typeholder}>
                        <h5 className={Sea.finaltext}>{val.etype}</h5>
                        <ul className={Sea.list}>
                            <li className={Sea.finaltext}>{val.lname}</li>
                        </ul>
                        </div>
                        <div className={Sea.dateholder}>
                        <h5 className={Sea.shorttext}>Expires on: {val.op_end_date}</h5>
                        <h5 className={Sea.shorttext}>Posted by: {val.postby}</h5>
                        </div>
                        <h6 className={Sea.applicants}>{val.app_count} Applicants</h6>
                        <div className={Sea.statusholder}>
                            <h5 className={Sea.applicants}>Status: {val.status && <span className={Sea.status} > Open</span>}
                            {!val.status && <span  > Close</span>}</h5>
                            <button className={Sea.viewjob} onClick={()=>{
                                        console.log(val)
                                        let sdata = { ...userDetails };
                                        sdata.vjob = val;
                                        sdata.appJob = appJob;
                                        sdata.prepage = '/searchresults';
                                         dispatch(awsSignIn(sdata));
                                         navigate("/viewjobs");
                                    }}>View Job</button>
                        </div>
                        </Paper>
                    </div>
 ))}
            </div>
            </Paper>
            
        </div>
    );
};

export default SearchResults;