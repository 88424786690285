import React, { useState, useEffect, useRef } from "react";
import Amplify, { API } from "aws-amplify";

import App from "./App";
import awsExports from "../config/aws-exports";
import { Constants } from "../config/constants";

function AppLoad() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // setTimeout(apiload, 5000);
    apiload();
  }, []);

  async function apiload() {
    let domain = window.location.hostname.replace("www.", "");
    console.log(domain);

    const bodyParam = {
      body: {
        domain: domain,
        //domain: "www.kmct.learned.cloud",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        awsExports.aws_cloud_logic_custom_name,
        // Constants.GET_COURSE_VIDEO,
        "/getConfig",
        bodyParam
      );
      console.log("getconfig", response);
      setConfig(response);
    } catch (error) {
      console.error(error);
    }
  }

  function setConfig(res) {
    //   let res = {
    //     "oid": "test3",
    //     "orgName": "test3",
    //     "title": "test1",
    //     "mainColor1": "#f18121",
    //     "mainColor2": "#0f80a4",
    //     "domain": "d2frts6cejszfa.cloudfront.net",
    //     "webpoolid": "us-east-1_rXFaGcfQn",
    //     "webclientid": "28d7ejvqb6kc4ogiuvrrpv4695",
    //     "adminpoolid": "us-east-1_Lu2NCS3mz",
    //     "adminclientid": "61kgmhlh0h0u77g7eujhatv7fo",
    //     "identitypoolid": "us-east-1:5d1e7759-6af1-4e1a-8f0b-f715d86cc99c"
    // };
    var r = document.querySelector(":root");
    // var rs = getComputedStyle(r);
    r.style.setProperty("--color-grey-dark-1", res.mainColor1);
    r.style.setProperty("--color-grey-dark-2", res.mainColor1);
    r.style.setProperty("--sub-heading-color", res.mainColor2);
    r.style.setProperty("--button-background", res.mainColor2);
    r.style.setProperty("--header-color", res.mainColor2);

    document.title = res.title;
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    link.href = `https://${
      res.domain
    }/${res.oid.toLowerCase()}-resources/images/org-images/logo.jpg`;
    // link.href = 'https://stackoverflow.com/favicon.ico';

    awsExports.aws_org_id = res.oid;
    awsExports.main_color_1 = res.mainColor1;
    awsExports.main_color_2 = res.mainColor2;
    awsExports.platform_main_theme = `linear-gradient(${res.mainColor1}, ${res.mainColor1})`;
    awsExports.button_color_web = res.mainColor1;
    awsExports.DOMAIN = res.domain;
    // alert(awsExports.main_color_1);
    awsExports.aws_cognito_identity_pool_id = res.identitypoolid;
    awsExports.aws_user_pools_id = res.webpoolid;
    awsExports.aws_user_pools_web_client_id = res.webclientid;

    Amplify.configure(awsExports);
    setIsLoading(false);
    // setTimeout(()=>{setIsLoading(false);}, 5000);
  }
  return <div>{isLoading ? "Loading....." : <App />}</div>;
}

export default AppLoad;
