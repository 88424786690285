// Dependencies imports
import React,{useEffect,useState} from 'react';
import Paper from "@material-ui/core/Paper";
import {Link,useNavigate} from 'react-router-dom';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import UserHeader from '../Header/UserHeader/UserHeader';
// Local imports
import { CircularProgress, IconButton } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import img2 from "../../assets/images/2img.png";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {ReactComponent as Close} from '../../assets/svg/close_black_24dp.svg';
import {ReactComponent as Delete} from '../../assets/svg/delete_black_24dp.svg';
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { API } from "aws-amplify";
import axios from "axios";
import { useSelector,useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { produce } from "immer";
import swal from 'sweetalert';
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Skeleton from '@material-ui/lab/Skeleton';
// Style imports
import Ex from "./MyProfile.module.scss";

const useStyle = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  uploadBtn: {
    color: "#fff",
    background: "#01498e",
    padding: ".5rem 5rem",
    fontSize: "1.4rem",
    "&:hover": {
      backgroundColor: "#01498e",
      color: "#fff",
      boxShadow: "#fff",
    },
  },
  fontSize: {},
  small: {
    fontSize: "1rem",
  },
  paper: {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "1100px",
      minHeight: "660px",
      overflowX: "hidden",
    },
  },
}));

const MyProfile = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector(authData);
  let navigate = useNavigate();
  const classes = useStyle();
  let skills ={
    "1":"Communication",
    "2":"Leadershitp",
    "3":"Problem Solving",
    "4":"Critical Thinking",
    "5":"Creativity",
    "6":"Teamwork"
  } ;
  
  let wrkexp = {"description":""};
  const [studentData,setStudentData] = useState();
  const [errData,setErrData] = useState({

  });
  const [primg,setPrimg] = useState(img2);
  const [resumefl,setResumefl] =useState();
  const [open, setOpen] = useState(false);
  const [uploadDisable, setUploadDisable] = useState(true);
  const [loading,setLoading] = useState(true);
  const [subloading,setSubLoading] = useState(false);
  const heading = {
    color: "white",
    fontSize: "14px"
  }
  useEffect(() => {
    
    getViewProfile();
   
    return () => {};
  }, []);

  function getViewProfile(type) {
    
    API.post(config.aws_cloud_logic_custom_name, "/getWorkAndAcademi", {
        body: {
            eid: userDetails.eid,
            oid: config.aws_org_id,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: jwttoken,
        },
      }).then((res) => {
        
   
    res.body.stdname =userDetails.name;
    res.body.email_id = userDetails.email;
    if(res?.body?.stddtl?.profile_img !== undefined && res?.body?.stddtl?.profile_img !== null){
      var extype = res?.body?.stddtl?.profile_img.slice( (Math.max(0, res?.body?.stddtl?.profile_img.lastIndexOf(".")) || Infinity) + 1);
      let primg1=`https://${config.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/images/users/${userDetails.eid}/crprofile/profile.${extype}`
      setPrimg(primg1);
    }
    if(res?.body?.stddtl?.resume_img !== undefined && res?.body?.stddtl?.resume_img !== null){
      let primg2=`https://${config.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/images/users/${userDetails.eid}/crresume/resumefile.pdf`
      setResumefl(primg2);
    }

    setStudentData(res.body);
    setLoading(false);
    if(type !== undefined) {
      setSubLoading(false);
    }
  
  })
  }
  const tablebody = {
    fontSize: "12px",
    fontWeight: 300,
  }

  const addWorkExp=()=>{
    //setStudentData(addwrkexp)
    setStudentData((currState) =>
            produce(currState, (v) => {
              v.workexp.push(wrkexp);
            })
          );
  }
  const checkworkexp = (erdata)=>{
    for(var k=0;k<studentData?.workexp?.length;k++){
      console.log("workloopp",studentData.workexp[k].jid)
      if(studentData.workexp[k].jid === undefined || studentData.workexp[k].jid.toString().replace(/ /gi, "").length === 0 ||
       studentData.workexp[k].stdate === undefined || studentData.workexp[k].stdate.toString().replace(/ /gi, "").length === 0
      || studentData.workexp[k].endate === undefined || studentData.workexp[k].endate.toString().replace(/ /gi, "").length === 0
      || studentData.workexp[k].description === undefined || studentData.workexp[k].description.toString().replace(/ /gi, "").length === 0){
        setErrData((currState) =>
        produce(currState, (v) => {
          erdata.status = true;
          v.eworkexp = 'Please fill all work experience';
          v.status = true;
        })
        );
        return true;
      }
    }
  }
  const saveProfileData = async (pstatus)=>{

   // if(pstatus === 1){
      let erdata = {...errData};
      erdata.status = false;
      setErrData((currState) =>
        produce(currState, (v) => {
         delete v.head_line ;
         delete  v.about_me ;
         delete v.resume_img ;
          v.status = false;
        })
        )
        if(studentData.workexp.length > 0 ){
          if(await checkworkexp(erdata)){
            erdata.status = true;
          }
        }
      if(studentData === undefined || studentData.stddtl === undefined){
        erdata.status = true;
        setErrData((currState) =>
        produce(currState, (v) => {
          v.head_line = 'Head line is required';
          v.about_me = 'About me is required';
          v.resume_img = 'Resume is required';
          v.status = true;
        })
        );

      } else {
        
        if(studentData.stddtl.head_line === undefined || studentData.stddtl.head_line.toString().replace(/ /gi, "").length === 0){
          erdata.status = true;
          setErrData((currState) =>
          produce(currState, (v) => {
            v.head_line = 'Head line is required';
            v.status = true;
          })
          );
        } 
         if(studentData.stddtl.about_me === undefined || studentData.stddtl.about_me === null
           || studentData.stddtl.about_me.toString().replace(/ /gi, "").length === 0){
          erdata.status = true;
          
          setErrData((currState) =>
                produce(currState, (v) => {
                  v.about_me = 'About me is required';
                  v.status = true;
                })
                );
        } 
         if(studentData.stddtl.resume_img === undefined || studentData.stddtl.resume_img === null
           || studentData.stddtl.resume_img.toString().replace(/ /gi, "").length === 0){
          erdata.status = true;
          setErrData((currState) =>
                produce(currState, (v) => {
                  v.resume_img = 'Resume is required';
                  v.status = true;
                })
                );
        }
      } 
     
    if(erdata.status){
              return;
    } else {
      var jid=[],stdt=[],endt=[],weds=[];
      for(var k=0;k<studentData.workexp.length;k++){
      jid.push(studentData.workexp[k].jid);
      stdt.push(studentData.workexp[k].stdate);
      endt.push(studentData.workexp[k].endate);
      weds.push(studentData.workexp[k].description); 
      }
     
      var obj = {};
      obj.atype = (studentData.stddtl === undefined || studentData.stddtl.profile_status === undefined) ? 1 : 2;
      obj.stdname = studentData.stdname;
      obj.eid = userDetails.eid;
      obj.email_id = userDetails.email;
      obj.colid = studentData.cldtl.cid;
      obj.head_line = studentData.stddtl.head_line;
      obj.about_me = studentData.stddtl.about_me;
      obj.s_skills = studentData.stddtl.skills;
      obj.profilestatus = pstatus;
      //  obj.prgid = null;
      //  obj.crid = null;
       obj.stdid = (studentData.stddtl === undefined || studentData.stddtl.profile_status === undefined) ? 0: studentData.stddtl.std_id;
       obj.j_id = jid;
       obj.startdate =stdt;
       obj.enddate = endt;
        obj.wdescr = weds;
        obj.phoneno = studentData.phoneno;
        obj.resumeimg = studentData.stddtl.resume_img;
        obj.profileimg = studentData.stddtl.profile_img;
      
        console.log("body===",obj);
        updateProfile(obj);
    }
  //}
   
  }
  function updateProfile(body) {
    //setLoading(true);
    setSubLoading(true);
    if(studentData !== undefined && studentData.stddtl !== undefined){
     
      if(studentData?.stddtl?.rsfile !== undefined){
        uploadDocsToServer(studentData?.stddtl?.rsfile, 'resume_img');
      }
     if(studentData?.stddtl?.pffile !== undefined){
        uploadDocsToServer(studentData?.stddtl?.pffile, 'profile_img');
      }
    }
   
    API.post(config.aws_cloud_logic_custom_name, "/updateStudentDetails", {
        body,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: jwttoken,
        },
      }).then((res) => {
        getViewProfile('type');
        setLoading(false);
        swal("","Profile submited successfully", "success");
      })
  }
  
  async function uploadDocsToServer(file,type) {
    var extype = file.name.slice( (Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1);
    var filename = '';
                      if(type === 'resume_img'){
                        filename =  `resumefile.${extype}`;
                      }
                      if(type === 'profile_img'){
                        filename =  `profile.${extype}`;
                      }
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        type,
        filetype: file.type,
        filename
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      let response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );

      fileUpload(file, response);
    } catch (error) {
      console.log("getCategoryError", error);
    }
  }

  async function fileUpload(file, url) {
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then((res) => {
      
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function handleProfileOrResumeFileFile(e,type) {
   
   
    let file = e.target.files[0];
    //studentData.stddtl[type] = file.name;
    setStudentData((currState) =>
                    produce(currState, (v) => {
                      if(v.stddtl === undefined){
                        v.stddtl = {};
                      }
                      v.stddtl[type] =  file.name;
                      if(type === 'resume_img'){
                        v.stddtl.rsfile = file;
                      }
                      if(type === 'profile_img'){
                        v.stddtl.pffile = file;
                      }
                    })
                    )
  }
  const deleteWorkexp =(e,windex)=>{
   setLoading(true);
    setStudentData((currState) =>
    produce(currState, (v) => {
      if(v.workexp !== undefined){
        v.workexp.splice(windex,1);
      }
    })
    )
    setLoading(false);
  }
  return (
    <div className={Ex.main}>
      <UserHeader />
      <h3 className={Ex.heading}>Profile</h3>
      
      {subloading && <Backdrop className={classes.backdrop} open={subloading}>
        <CircularProgress color="inherit" />
      </Backdrop>} 
     
    {!loading && <Paper>
    <div className={Ex.tobbar}>
        <div className={Ex.searchoptions}>
          <div className={Ex.imageholder}>
          
        <img src={studentData?.stddtl?.pffile !== undefined ? URL.createObjectURL(studentData?.stddtl?.pffile) : primg} 
        onError={(e) => (e.target.onerror = null, e.target.src = img2)}
        className={Ex.img} alt="" />
          <input   accept="image/*"
                            className={classes.input}
                            id="containedbuttonimgfile"
                            multiple
                            type="file"
                            onChange={(e) => {
                              handleProfileOrResumeFileFile(e, 'profile_img');
                            }}
                          />
                          <label htmlFor="containedbuttonimgfile" className={Ex.changebutton}>
                            <Button
                              component="span"
                              className={Ex.changebutton}
                            >
                              Change Photo
                            </Button>
                          </label>
                        
            {/* <button className={Ex.changebutton}>Change Photo</button> */}
          </div>
          <div className={Ex.rightholder}>
            <div className={Ex.labelandselect}>
              <label className={Ex.label}>Name</label>
              <input
                type="text"
                defaultValue={studentData?.stdname}
                className={Ex.inputLiveSession}
                onChange={(event)=>{
                  setStudentData((currState) =>
                  produce(currState, (v) => {
                    v.stdname= event.target.value;
                  })
                  )
                }}
              />
            </div>
            <div className={Ex.labelandselect}>
              <label className={Ex.label}>Email</label>
              <input disabled
                type="text"
                defaultValue={studentData?.email_id}
                className={Ex.inputLiveSession}
                onChange={(event)=>{
                  setStudentData((currState) =>
                  produce(currState, (v) => {
                    v.email_id = event.target.value;
                  })
                  )
                }}
              />
            </div>
            <div className={Ex.labelandselect}>
              <label className={Ex.label}>Phone</label>
              <input
                defaultValue={studentData?.phoneno === undefined ? studentData?.stddtl?.ph_no : studentData?.phoneno}
                className={Ex.inputLiveSession}
                maxLength={10}
                type="text"
                pattern="[7-9]{1}[0-9]{9}"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e)=>{
                  const re = /^[0-9\b]+$/;
                  if ( re.test(e.target.value)) {
                      setStudentData((currState) =>
                      produce(currState, (v) => {
                        v.phoneno = e.target.value;
                      })
                      )
                  }
                }}
              />
            </div>
            <div className={Ex.labelandselect}>
              <label className={Ex.label}>Location</label>
              <input
                type="text" disabled
                defaultValue={studentData?.cldtl?.city}
                className={Ex.inputLiveSession}
                onChange={(event)=>{
                  setStudentData((currState) =>
                  produce(currState, (v) => {
                    v.loc = event.target.value;
                  })
                  )
                }}
              />
            </div>
            <div className={Ex.labelandselect}>
              <label className={Ex.label}>College</label>
              <input disabled
                type="text"
                defaultValue={studentData?.cldtl?.cname}
                className={Ex.inputLiveSession}
                onChange={(event)=>{
                  setStudentData((currState) =>
                  produce(currState, (v) => {
                    v.col = event.target.value;
                  })
                  )
                }}
              />
            </div>
            <div className={Ex.labelandselect}>
              <label className={Ex.label}>Resume</label>
                
              <input   accept="application/pdf"
                            className={classes.input}
                            id="containedbuttonpdffile"
                            multiple
                            type="file"
                            onChange={(e) => {
                              handleProfileOrResumeFileFile(e, 'resume_img');
                            }}
                          />

                          <label htmlFor="containedbuttonpdffile" className={Ex.changebutton}>
                            <Button
                              component="span"
                              className={Ex.changebutton}
                            >
                              Resume
                            </Button>
                          </label>    
            </div>
            {(studentData?.stddtl !== undefined && studentData?.stddtl?.resume_img !== undefined) &&  <> 
                <label className={Ex.label} style={{padding:'0px 0px 0px 202px',color:'blue',cursor:'pointer'}}
                onClick={()=> setOpen(true)}>{studentData.stddtl.resume_img}</label>
            </>}
            {(errData.status && errData.resume_img) &&  <> 
                <label className={Ex.label} style={{padding:'0px 0px 0px 202px',width:'332px',color:'red'}}>
                  * {errData.resume_img}</label>
            </>}
          </div>
        </div>
      <div style={{ padding: "20px 10px 10px 10px" }}>
        <h3 className={Ex.heading} >Headline</h3>

        <input
          type="text"
          defaultValue={studentData?.stddtl?.head_line}
          style={{ width: "100%" }}
          placeholder="Pharmacy graduate 2022"
          className={Ex.inputLiveSession}
          onChange={(event)=>{
            setStudentData((currState) =>
            produce(currState, (v) => {
              if(v.stddtl === undefined) {
                v.stddtl = {};
              }
             
                v.stddtl.head_line = event.target.value;
            })
            )
          }}
        />
        {(errData.status && errData.head_line) &&  <> 
                <label className={Ex.label} style={{color:'red'}}>
                  * {errData.head_line}</label>
            </>}
      </div>
      <div style={{ padding: "20px 10px 10px 10px" }}>
        <h3 className={Ex.heading} >About Me</h3>

        <textarea
          type="text"
          placeholder="I've been a lab assistant for a year now. I have also been involved in college activities frequently. I'm looking to work as a..."
          defaultValue={studentData?.stddtl?.about_me}
          style={{ width: "100%" }}
          rows={3}
          className={Ex.aboutme}
          onChange={(event)=>{
            setStudentData((currState) =>
            produce(currState, (v) => {
              if(v.stddtl === undefined) {
                v.stddtl = {};
              }
             
              v.stddtl.about_me =event.target.value;
              
            })
            )
          }}
        />
          {(errData.status && errData.about_me) &&  <> 
                <label className={Ex.label} style={{color:'red'}} >
                  * {errData.about_me}</label>
          </>}
      </div>
    </div>
    <div className={Ex.detailsholder}>
      <div className={Ex.skillsetholder}>
        <div className={Ex.searchskills}>
          <h3 className={Ex.searchheading}>Skills</h3>
          <select className={Ex.select} onChange={(event) => {
              
              //const selectedIndex = event.target.options.selectedIndex;
              setStudentData((currState) =>
              produce(currState, (v) => {
                if(v.stddtl === undefined){
                  v.stddtl = {};
                } 
                if(v.stddtl.skills === undefined){
                  v.stddtl.skills=[];
                }
                if(!v.stddtl.skills.includes(event.target.value)){
                  v.stddtl.skills.push(event.target.value);
                } 
                
              })
              )
          }}>
            <option className={Ex.skilloption} disabled selected>Search for skills here</option>
            {Object.keys(skills).map((sval,index) => (
              <option clasName={Ex.skilloption} value={sval}>{skills[sval]}</option>
            ))}
            
          </select>
        </div>
        <div className={Ex.tagholder}>
          {studentData?.stddtl?.skills?.map((value,index) => (<>
            <div className={Ex.tag}>
              <h4 className={Ex.tagname}>{skills[value]}</h4>
              <Close className={Ex.close} />
            </div>
          </>))}
        </div>
        {(errData.status && errData.skills) &&  <> 
                <label className={Ex.label} >
                  {errData.skills}</label>
          </>}
      </div>
      <div className={Ex.Workexholder}>
        <h3 className={Ex.workheading}>Work experience</h3>
        {(errData?.status && errData?.eworkexp) &&  <> 
                <label className={Ex.label} style={{width:'100%',color:'red',textAlign:'left'}}>
                  * {errData.eworkexp}</label>
            </>}
          
        { (studentData !== undefined && studentData?.workexp.length > 0) && studentData?.workexp.map((wval,windex) => ( 
          <>
          <div className={Ex.worktopbar}>
<div className={Ex.jobtitle}>
            <h4 className={Ex.title}>Job title</h4>
            <select className={Ex.select} defaultValue={wval.jid !== undefined ? parseInt(wval.jid): -1}
            onChange={(event) => {
              //console.log("keyyy==",event.target.getAttribute("key"))
              //const selectedIndex = event.target.options.selectedIndex;
              setStudentData((currState) =>
              produce(currState, (v) => {
                if(v.workexp === undefined) {
                  v.workexp = [];
                }
                v.workexp[windex].jid = event.target.value;
              })
              )
          }}>
            <option  value={-1}  selected="selected" disabled>Select Job</option>
              {studentData.joblist?.map((jval,index) => (
        <option value={jval.jid} key={index}>{jval.title.substring(0,40)}{jval.title.length > 40 ? "..." : null}</option>
      ))};
            </select>
          </div>
          <div className={Ex.jobtitle}>
            <h4 className={Ex.title}>Start date</h4>
            <input className={Ex.dateselect} type="date" style={{fontSize:'15px'}} value={wval.stdate} onChange={(event)=>{
                          setStudentData((currState) =>
                          produce(currState, (v) => {
                            if(v.workexp=== undefined){
                              v.workexp = [];
                            }
                            v.workexp[windex].stdate = event.target.value;
                          })
                          )
            }}></input>
          </div>
          <div className={Ex.jobtitle}>
            <h4 className={Ex.title}>End date</h4>
            <input className={Ex.dateselect} type="date" style={{fontSize:'15px'}} value={wval.endate} onChange={(event)=>{
              
              setStudentData((currState) =>
              produce(currState, (v) => {
                v.workexp[windex].endate = event.target.value;
              })
              )
            }}></input>
          </div>
          <div className={Ex.deleteholder}>
            <Delete className={Ex.delete} onClick={(e)=>deleteWorkexp(e,windex)} />
          </div>
        </div>
        <div className={Ex.jobdescription}>
        <textarea className={Ex.description} rows={5} placeholder="I've been a lab assisatant for a year now. I have also been involved in college activities frequently..."
        value={wval.description} onChange={(event)=>{
          
          setStudentData((currState) =>
          produce(currState, (v) => {
            if(v.workexp === undefined){
              v.workexp = [];
            }
            v.workexp[windex].description = event.target.value;
          })
          )
        }}>

        </textarea>
      </div>
          </>
          ))
        }
        <hr />
        <button className={Ex.addbutton} onClick={addWorkExp}>Add</button>
      </div>
    </div>
    <hr />
    <div className={Ex.academicstable}>
      <h3 className={Ex.academics}>
        Academics
      </h3>
      <div className={Ex.tableholder}>
        <Table>
          <TableHead style={{ background: config.main_color_1 , color: "white", fontSize: "14px" }}>
            <TableRow>
              <TableCell style={heading}>Programs</TableCell>
              <TableCell style={heading}>Courses</TableCell>
              <TableCell style={heading}>Grades</TableCell>
              <TableCell style={heading}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentData?.academi?.map((value,index) => (  
              <TableRow>
                <TableCell>
                  <h3 style={tablebody}>{value.pname}</h3>
                </TableCell>
                <TableCell>
                  
                    {value.cname !== undefined && value.cname.map((cval,index) => (<>
                    <h3 style={tablebody}> {cval}</h3>
                    </>))}
                  
                </TableCell>
                <TableCell><h3 style={tablebody}>{value.grade}/10</h3></TableCell>
                <TableCell><h3 style={tablebody}>Pass</h3></TableCell>
              </TableRow>
            ))}
{/*                 
              <TableRow>
                <TableCell>
                  <h3 style={tablebody}>Bachelor om Pharmacy</h3>
                </TableCell>
                <TableCell>
                  <h3 style={tablebody}>Cell and gene therapy</h3>
                  <h3 style={tablebody}>FDC summary</h3>
                </TableCell>
                <TableCell>
                  <h3 style={tablebody}>-</h3>
                </TableCell>
                <TableCell>
                  <h3 style={tablebody}>In progress</h3>
                </TableCell>
              </TableRow> */}
          </TableBody>
        </Table>
      </div>
    </div>
    <div className={Ex.buttonholder}>
      {/* {(studentData?.stddtl === undefined || studentData?.stddtl.profile_status === undefined
      || studentData?.stddtl.profile_status === 0) && */}
        <button className={Ex.searchbutton} onClick={()=>saveProfileData(0)}>Save</button>
      
      {/* {(studentData?.stddtl === undefined || studentData?.stddtl.profile_status === undefined
      || studentData?.stddtl.profile_status === 0 || studentData?.stddtl.profile_status === 3) && */}
      <button className={Ex.searchbutton} style={{margin:'0 0 0 10px'}}
        onClick={()=>saveProfileData(1)}>Save and send for approval</button>
      
      
    </div>
    </Paper>}
    <Dialog 
        open={open}
        scroll={"body"}
        className={classes.paper}
        onClose={()=>{
          setOpen(false);
        }}
        aria-labelledby="responsive-dialog-title"
        onBackdropClick="false"
      >
        <div style={{ position: "absolute", top: "0", right: "0" }}>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* <DialogTitle id="responsive-dialog-title" style={{ textAlign: "left" }}>
          <h1 style={{ fontSize: "18px", textAlign: "left" }}>
            {curObject.otitle}
          </h1>
        </DialogTitle> */}
        <DialogContent>
          <iframe 
          src={studentData?.stddtl?.rsfile !== undefined ? URL.createObjectURL(studentData?.stddtl?.rsfile) : resumefl}
           style={{width:'100%',height:'640px',marginTop:'40px'}} ></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MyProfile;
