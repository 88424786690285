import React from "react";

const Reject = ({ className, fill }) => {
  return (
    <svg
      className={className}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="225.000000pt"
      height="225.000000pt"
      viewBox="0 0 225.000000 225.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
        fill="#c93232"
        stroke="none"
      >
        <path
          d="M997 2240 c-525 -60 -936 -483 -988 -1018 -13 -128 -2 -253 32 -382
58 -221 191 -431 361 -570 65 -54 208 -150 223 -150 2 0 28 -11 57 -24 241
-110 551 -121 811 -30 162 57 289 135 410 253 276 267 396 652 321 1026 -17
83 -39 154 -65 212 -10 24 -19 45 -19 48 0 2 -23 43 -51 90 -127 216 -314 376
-549 470 -164 66 -371 94 -543 75z m-324 -464 c18 -8 126 -109 242 -225 l210
-211 220 219 c236 236 246 242 330 227 46 -9 102 -65 111 -111 15 -84 9 -94
-227 -330 l-219 -220 211 -210 c116 -116 217 -224 225 -242 53 -123 -67 -249
-193 -203 -15 6 -125 107 -243 225 l-215 215 -210 -211 c-119 -119 -225 -216
-244 -225 -122 -52 -244 61 -202 187 7 23 79 102 226 249 l215 215 -219 220
c-209 210 -220 222 -226 266 -10 70 20 133 77 159 51 23 87 25 131 6z"
        />
      </g>
    </svg>
  );
};
export default Reject;
