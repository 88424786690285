import React from "react";

const Announcements = ({ className, cls1 }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 120"
      className={className}
    >
      <defs></defs>
      <path
        className={cls1}
        d="M64.2,36.5A179,179,0,0,1,81.6,84.05c-1.41-.29-2.53-.46-3.62-.74C68.9,81,59.82,78.75,50.32,79.49c-2.59.2-5.17.53-7.74,1-1,.16-1.44-.07-1.76-1-1.67-5-3.41-10-5-15.07a1.77,1.77,0,0,1,.6-1.59C46.31,57,53.82,48.62,61.11,40,62,39,62.89,38,64.2,36.5Z"
      />
      <path
        className={cls1}
        d="M65.7,35.84c2-.62,2.89.46,3.64,1.86A158.7,158.7,0,0,1,81.08,65.59C82.39,69.75,83.5,74,84.6,78.21a9.66,9.66,0,0,1,.22,3.47,4.08,4.08,0,0,1-1.37,2.14A192.46,192.46,0,0,0,65.7,35.84Z"
      />
      <path
        className={cls1}
        d="M82.58,62.76l-3.13-8.65c3.46-1.25,4.77-.65,6.08,2.69C86.65,59.67,85.86,61.33,82.58,62.76Z"
      />
      <path
        className={cls1}
        d="M33.85,63.91c-4.25,1.47-8.43,2.81-12.49,4.4A3.81,3.81,0,0,0,19,72.69a20.94,20.94,0,0,0,3.93,9.47,4.63,4.63,0,0,0,5,2c3.84-.91,7.64-2,11.72-3.06C37.68,75.25,35.83,69.74,33.85,63.91Zm-11.1,9,0-.06a.9.9,0,0,1,.57-1.13l7.64-2.45a.89.89,0,0,1,1.12.57l0,.08A.88.88,0,0,1,31.51,71l-7.64,2.46A.89.89,0,0,1,22.75,72.86Zm1.31,3.89,0-.06a.89.89,0,0,1,.57-1.13l7.64-2.44a.89.89,0,0,1,1.13.57l0,.07a.91.91,0,0,1-.59,1.13l-7.62,2.45A.91.91,0,0,1,24.06,76.75Zm10.09,2-7.63,2.44a.88.88,0,0,1-1.13-.57l0-.07a.89.89,0,0,1,.57-1.12l7.64-2.46a.9.9,0,0,1,1.12.59l0,.07A.9.9,0,0,1,34.15,78.71Z"
      />
      <path
        className={cls1}
        d="M100.17,57.51l-8,0a.88.88,0,0,1-.9-.89v-.07a.9.9,0,0,1,.89-.89h8a.88.88,0,0,1,.89.9v.07A.91.91,0,0,1,100.17,57.51Z"
      />
      <path
        className={cls1}
        d="M96.44,45.19l-6.09,5.22a.88.88,0,0,1-1.26-.09l0,0A.9.9,0,0,1,89.14,49l6.08-5.23a.89.89,0,0,1,1.26.1l0,.06A.9.9,0,0,1,96.44,45.19Z"
      />
      <path
        className={cls1}
        d="M84.1,38.7l-.9,8a.88.88,0,0,1-1,.8h-.07a.9.9,0,0,1-.79-1l.89-8a.89.89,0,0,1,1-.78h.07A.89.89,0,0,1,84.1,38.7Z"
      />
      <path
        className={cls1}
        d="M90.5,64.05l6.69,4.42a.89.89,0,0,1,.26,1.24l0,.06a.91.91,0,0,1-1.24.26l-6.69-4.42a.89.89,0,0,1-.25-1.24l0-.06A.9.9,0,0,1,90.5,64.05Z"
      />
    </svg>
  );
};

export default Announcements;

// <style>.cls-1{fill:#e35f14;}</style>
