// Dependenciess improts
import React,{useState, useEffect} from 'react'
import {Link,useNavigate} from 'react-router-dom';
import Paper from "@material-ui/core/Paper";
import { useSelector,useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
// Local imports

import config from "../../config/aws-exports"
import { API } from "aws-amplify";
// Style imports
import View from './ViewJob.module.scss';
import swal from 'sweetalert';
import UserHeader from '../Header/UserHeader/UserHeader';

const ViewJob = () => {
    const userDetails = useSelector(authData);
     let sdata = { ...userDetails };
     const [loading, setLoading] = useState(false);
    console.log("userdttt===",userDetails)
    let navigate = useNavigate();
    useEffect(() => {
        console.log(userDetails,"==userDetails==")
        getProfileStatus();
        return () => {};
      }, []);
    function getProfileStatus() {
        console.log('eid==',userDetails.eid)
        setLoading(true);
        API.post(config.aws_cloud_logic_custom_name, "/appliedSavedJob", {
            body: {
                eid: userDetails.eid,
            },
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // Authorization: jwttoken,
            },
          }).then((res) => {
            console.log(res);
            setLoading(false);
           
           // setMasterData(res.body);
            
          })
    }
    function submitJob() {
        console.log('jid==',userDetails.vjob.jid)
        console.log('eid==',userDetails.eid)
        setLoading(true);
        API.post(config.aws_cloud_logic_custom_name, "/appliedSavedJob", {
            body: {
                jobid: userDetails.vjob.jid,
                eid: userDetails.eid,
                apstatus: 0,
                statustype:1
            },
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // Authorization: jwttoken,
            },
          }).then((res) => {
            console.log(res);
            sdata.appJob.push(sdata.vjob.jid)
            setLoading(false);
            swal("","Job Applied successfully", "success");
            navigate(userDetails.prepage);
           // setMasterData(res.body);
            
          })
    }
    return (
        <div className={View.maincontainer}>
            <UserHeader />
            { userDetails.vjob !== undefined &&
            <>
            <h3 className={View.heading}>View Job</h3>
            <Paper>
            <div className={View.topbar}>
                <div className={View.left}>
                    <h3 className={View.jobheading}> {userDetails.vjob.title} - {userDetails.vjob.cname}</h3>
                    <div className={View.typeholder}>
                        <h5 className={View.finaltext}>{userDetails.vjob.etype}</h5>
                        <ul className={View.list}>
                            <li className={View.finaltext}>{userDetails.vjob.lname}</li>
                        </ul>
                    </div>
                    <div className={View.typeholder}>
                        <h5 className={View.finaltext}>{userDetails.vjob.no_opens} openings</h5>
                        <ul className={View.list}>
                            <li className={View.finaltext}>{userDetails.vjob.app_count} Applicants</li>
                        </ul>
                    </div>
                    <h5 className={View.secondtext}>Required Experience: {userDetails.vjob.reqexp}</h5>
                    <h5 className={View.secondtext}>CTC: {userDetails.vjob.ctc} {userDetails.vjob.ctype}</h5>
                    <h5 className={View.applicants}>Status: <span className={View.status}>{userDetails.vjob.status === true ? 'Open':'close'}</span></h5>
                </div>
                <div className={View.right}>
                    <h5 className={View.secondtext}>Expires on: {userDetails.vjob.op_end_date}</h5>
                    <h5 className={View.secondtext}>Posted by: {userDetails.vjob.postby}</h5>
                </div>
            </div>
            <div className={View.bottombar}>
                <h3 className={View.jobheading}>Job description:</h3>
                <p className={View.jobdescription}>
                    {userDetails.vjob.description}
                </p>
                {/* <h4 className={View.response}>
                    Responsibilities:
                </h4>
                <p className={View.jobdescription}>Set design requiremnts based on infomration from internal teams and user research.</p>
                <p className={View.jobdescription}>Identify new product improvement opportunites</p>
                <p className={View.jobdescription}>Develop and maintain a design system for our product with our design team.</p>
                <p className={View.jobdescription}>Modify and revise existing designs to meet changing user preferences.</p>
                <p className={View.jobdescription}>Present and defend your design decisions and stick to well-know design guidelines while designing interfaces.</p>
                <h4 className={View.response}>
                    Requirements:
                </h4>
                <p className={View.jobdescription}>Experience in most phases of the design process including user research, copywriting, protoyping, vissual design , interaction design, and usuablitlity testing.</p>
                <p className={View.jobdescription}>Experience with Figma and its design system features.</p>
                <p className={View.jobdescription}>Excellent attention to detail</p>
                <p className={View.jobdescription}>Passiodn for design; alwayss thinking of ways to improve.</p>
                <p className={View.jobdescription}>Able to iterate fast- (Crazy fast, we'll be helping you)</p> */}
            </div>
            <div className={View.buttonholder}>
                <button className={View.back} onClick={()=>
                    navigate(userDetails.prepage)}>Back</button>
               {!loading && (sdata.appJob === undefined || !sdata.appJob.includes(sdata.vjob.jid)) &&
               <button className={ (userDetails.pfstatus === undefined || userDetails.pfstatus !== 2) ?( View.btndisable +' '+View.apply ): View.apply} 
                onClick={()=>{
                    if(sdata.vjob !== undefined){
                        submitJob();
                    }
                    }}>Apply</button>
               } 
            </div>
            </Paper>
            </>
}
        </div>
    );
};

export default ViewJob;